/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'

import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
import PostsLint from 'src/components/postsList'

const Category = ({ data }) => {
  const category = data.allWordpressCategory.edges[0]

  return (
    <Layout>
      <SEO title={category.node.name} />
      <PostsLint
        categoryId={category.node.wordpress_id}
        title={category.node.name}
        subtitle="Рубрика"
      />
    </Layout>
  )
}

export default Category

export const query = graphql`
  query($id: Int!) {
    allWordpressCategory(filter: { wordpress_id: { eq: $id } }) {
      edges {
        node {
          wordpress_id
          name
        }
      }
    }
  }
`
